var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.blogDetail).length
    ? _c(
        "content-with-sidebar",
        { staticClass: "cws-container cws-sidebar-right blog-wrapper" },
        [
          _c(
            "div",
            { staticClass: "blog-detail-wrapper" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-card",
                        {
                          attrs: {
                            "img-src": _vm.blogDetail.blog.img,
                            "img-top": "",
                            "img-alt": "Blog Detail Pic",
                            title: _vm.blogDetail.blog.title
                          }
                        },
                        [
                          _c(
                            "b-media",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-media-aside",
                                {
                                  staticClass: "mr-50",
                                  attrs: { "vertical-align": "center" }
                                },
                                [
                                  _c("b-avatar", {
                                    attrs: {
                                      href: "javascript:void(0)",
                                      size: "24",
                                      src: _vm.blogDetail.blog.avatar
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("b-media-body", [
                                _c(
                                  "small",
                                  { staticClass: "text-muted mr-50" },
                                  [_vm._v("by")]
                                ),
                                _c(
                                  "small",
                                  [
                                    _c("b-link", { staticClass: "text-body" }, [
                                      _vm._v(
                                        _vm._s(_vm.blogDetail.blog.userFullName)
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { staticClass: "text-muted ml-75 mr-50" },
                                  [_vm._v("|")]
                                ),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    _vm._s(_vm.blogDetail.blog.createdTime)
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "my-1 py-25" },
                            _vm._l(_vm.blogDetail.blog.tags, function(tag) {
                              return _c(
                                "b-link",
                                { key: tag },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "mr-75",
                                      attrs: {
                                        pill: "",
                                        variant: _vm.tagsColor(tag)
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(tag) + " ")]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c("div", {
                            staticClass: "blog-content",
                            domProps: {
                              innerHTML: _vm._s(_vm.blogDetail.blog.content)
                            }
                          }),
                          _vm._l(_vm.blogDetail.blog.UserComment, function(
                            user
                          ) {
                            return _c(
                              "b-media",
                              { key: user.avatar, attrs: { "no-body": "" } },
                              [
                                _c(
                                  "b-media-aside",
                                  [
                                    _c("b-avatar", {
                                      attrs: { size: "60", src: user.avatar }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-media-body",
                                  [
                                    _c(
                                      "h6",
                                      { staticClass: "font-weight-bolder" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(user.fullName) + " "
                                        )
                                      ]
                                    ),
                                    _c("b-card-text", [
                                      _vm._v(" " + _vm._s(user.comment) + " ")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _c("hr", { staticClass: "my-2" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center mr-1"
                                    },
                                    [
                                      _c(
                                        "b-link",
                                        { staticClass: "mr-50" },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "text-body",
                                            attrs: {
                                              icon: "MessageSquareIcon",
                                              size: "21"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("b-link", [
                                        _c(
                                          "div",
                                          { staticClass: "text-body" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.kFormatter(
                                                    _vm.blogDetail.blog.comments
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center"
                                    },
                                    [
                                      _c(
                                        "b-link",
                                        { staticClass: "mr-50" },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "text-body",
                                            attrs: {
                                              size: "21",
                                              icon: "BookmarkIcon"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("b-link", [
                                        _c(
                                          "div",
                                          { staticClass: "text-body" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.kFormatter(
                                                    _vm.blogDetail.blog
                                                      .bookmarked
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "blog-detail-share" },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      attrs: {
                                        variant: "link",
                                        "toggle-class": "p-0",
                                        "no-caret": "",
                                        right: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function() {
                                              return [
                                                _c("feather-icon", {
                                                  staticClass: "text-body",
                                                  attrs: {
                                                    size: "21",
                                                    icon: "Share2Icon"
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        710295190
                                      )
                                    },
                                    _vm._l(_vm.socialShareIcons, function(
                                      icon
                                    ) {
                                      return _c(
                                        "b-dropdown-item",
                                        { key: icon, attrs: { href: "#" } },
                                        [
                                          _c("feather-icon", {
                                            attrs: { icon: icon, size: "18" }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-2",
                      attrs: { id: "blogComment", cols: "12" }
                    },
                    [
                      _c("h6", { staticClass: "section-label" }, [
                        _vm._v(" Comment ")
                      ]),
                      _vm._l(_vm.blogDetail.comments, function(comment, index) {
                        return _c(
                          "b-card",
                          { key: index },
                          [
                            _c(
                              "b-media",
                              { attrs: { "no-body": "" } },
                              [
                                _c(
                                  "b-media-aside",
                                  { staticClass: "mr-75" },
                                  [
                                    _c("b-avatar", {
                                      attrs: { src: comment.avatar, size: "38" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-media-body",
                                  [
                                    _c(
                                      "h6",
                                      {
                                        staticClass: "font-weight-bolder mb-25"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(comment.userFullName) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("b-card-text", [
                                      _vm._v(_vm._s(comment.commentedAt))
                                    ]),
                                    _c("b-card-text", [
                                      _vm._v(
                                        " " + _vm._s(comment.commentText) + " "
                                      )
                                    ]),
                                    _c("b-link", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-inline-flex align-items-center"
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-50",
                                            attrs: {
                                              icon: "CornerUpLeftIcon",
                                              size: "18"
                                            }
                                          }),
                                          _c("span", [_vm._v("Reply")])
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-2", attrs: { cols: "12" } },
                    [
                      _c("h6", { staticClass: "section-label" }, [
                        _vm._v(" Leave a Comment ")
                      ]),
                      _c(
                        "b-card",
                        [
                          _c(
                            "b-form",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              name: "name",
                                              placeholder: "Name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              name: "email",
                                              type: "email",
                                              placeholder: "Email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              name: "website",
                                              placeholder: "Website"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              name: "textarea",
                                              rows: "4",
                                              placeholder: "Website"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            id: "checkbox-1",
                                            name: "checkbox-1"
                                          },
                                          model: {
                                            value: _vm.commentCheckmark,
                                            callback: function($$v) {
                                              _vm.commentCheckmark = $$v
                                            },
                                            expression: "commentCheckmark"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " Save my name, email, and website in this browser for the next time I comment. "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple.400",
                                              value:
                                                "rgba(255, 255, 255, 0.15)",
                                              expression:
                                                "'rgba(255, 255, 255, 0.15)'",
                                              modifiers: { "400": true }
                                            }
                                          ],
                                          attrs: { variant: "primary" }
                                        },
                                        [_vm._v(" Post Comment ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "blog-sidebar py-2 py-lg-0",
              attrs: { slot: "sidebar" },
              slot: "sidebar"
            },
            [
              _c(
                "b-form-group",
                { staticClass: "blog-search" },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "input-group-merge" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "search-input",
                          placeholder: "Search here"
                        },
                        model: {
                          value: _vm.search_query,
                          callback: function($$v) {
                            _vm.search_query = $$v
                          },
                          expression: "search_query"
                        }
                      }),
                      _c(
                        "b-input-group-append",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { "is-text": "" }
                        },
                        [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "blog-recent-posts mt-3" },
                [
                  _c("h6", { staticClass: "section-label mb-75" }, [
                    _vm._v(" Recent Posts ")
                  ]),
                  _vm._l(_vm.blogSidebar.recentPosts, function(
                    recentpost,
                    index
                  ) {
                    return _c(
                      "b-media",
                      {
                        key: recentpost.img,
                        class: index ? "mt-2" : "",
                        attrs: { "no-body": "" }
                      },
                      [
                        _c(
                          "b-media-aside",
                          { staticClass: "mr-2" },
                          [
                            _c(
                              "b-link",
                              [
                                _c("b-img", {
                                  attrs: {
                                    src: recentpost.img,
                                    alt: recentpost.img.slice(6),
                                    width: "100",
                                    rounded: "",
                                    height: "70"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("b-media-body", [
                          _c(
                            "h6",
                            { staticClass: "blog-recent-post-title" },
                            [
                              _c(
                                "b-link",
                                { staticClass: "text-body-heading" },
                                [_vm._v(" " + _vm._s(recentpost.title) + " ")]
                              )
                            ],
                            1
                          ),
                          _c("span", { staticClass: "text-muted mb-0" }, [
                            _vm._v(" " + _vm._s(recentpost.createdTime) + " ")
                          ])
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "blog-categories mt-3" },
                [
                  _c("h6", { staticClass: "section-label mb-1" }, [
                    _vm._v(" Categories ")
                  ]),
                  _vm._l(_vm.blogSidebar.categories, function(category) {
                    return _c(
                      "div",
                      {
                        key: category.icon,
                        staticClass:
                          "d-flex justify-content-start align-items-center mb-75"
                      },
                      [
                        _c(
                          "b-link",
                          [
                            _c(
                              "b-avatar",
                              {
                                staticClass: "mr-75",
                                attrs: {
                                  rounded: "",
                                  size: "32",
                                  variant: _vm.tagsColor(category.category)
                                }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: category.icon, size: "16" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("b-link", [
                          _c(
                            "div",
                            { staticClass: "blog-category-title text-body" },
                            [_vm._v(" " + _vm._s(category.category) + " ")]
                          )
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }